import React, { useEffect, useRef, useMemo, useState, useCallback } from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';
import { Base64 } from 'js-base64';
import { API_BASE_URL } from "../App";
import '../css/ChartSection.css'; // Ensure relevant styles are added

function TradingViewChart({ symbol }) {
    const chartContainerRef = useRef(null);
    const chartRef = useRef(null);
    const candlestickSeriesRef = useRef(null);
    const volumeSeriesRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [chartReady, setChartReady] = useState(false);

    // Generate the API endpoint using useMemo
    const finalApiEndpoint = useMemo(() => {
        if (!symbol) return null;
        const filters = { symbol };
        const encodedFilters = Base64.encodeURL(JSON.stringify(filters));
        return `${API_BASE_URL}/historical-data/?filters=${encodedFilters}`;
    }, [symbol]);

    // Fetch data from the API and populate the chart
    const fetchChartData = useCallback(async () => {
        if (!finalApiEndpoint || !chartReady) {
            console.log('Chart not ready yet or missing API endpoint');
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(finalApiEndpoint);
            const data = await response.json();

            // Adjust the 'open', 'high', and 'low' prices
            const chartData = data.map((item, index) => {
                let openPrice;
                if (index === 0) {
                    // For the first candle, use the 'close' price as 'open'
                    openPrice = item.close;
                } else {
                    // For subsequent candles, use the previous candle's 'close' price
                    openPrice = data[index - 1].close;
                }
                return {
                    time: item.quote_time,
                    open: openPrice,
                    high: item.close, // Set high equal to close
                    low: item.close,  // Set low equal to close
                    close: item.close,
                    volume: item.volume,
                };
            });

            console.log('Chart data:', chartData);

            // Update the chart with the new data
            candlestickSeriesRef.current.setData(chartData);

            // Update volume series data
            const volumeData = chartData.map(d => ({
                time: d.time,
                value: d.volume,
            }));
            volumeSeriesRef.current.setData(volumeData);

            console.log('Chart data set');
        } catch (error) {
            console.error("Error fetching chart data:", error);
        } finally {
            setLoading(false);
        }
    }, [finalApiEndpoint, chartReady]);

    useEffect(() => {
        if (!chartReady || !finalApiEndpoint) return;

        // Fetch data initially
        fetchChartData();

        // Set up polling interval
        const intervalId = setInterval(() => {
            fetchChartData();
        }, 120000); // 2 minutes in milliseconds

        // Cleanup interval on unmount or dependency change
        return () => clearInterval(intervalId);
    }, [fetchChartData, chartReady, finalApiEndpoint]);

    // Initialize chart and series
    useEffect(() => {
        if (!chartContainerRef.current) return;

        // Initialize the chart
        chartRef.current = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
            layout: {
                backgroundColor: '#ffffff',
                textColor: '#000000',
            },
            grid: {
                vertLines: { color: '#e1e3e6' },
                horzLines: { color: '#e1e3e6' },
            },
            rightPriceScale: {
                borderVisible: true,
            },
            leftPriceScale: {
                visible: true,
            },
            timeScale: {
                borderVisible: true,
                timeVisible: true,
            },
            crosshair: {
                mode: CrosshairMode.Normal,
            },
            handleScroll: {
                vertTouchDrag: true,
                horzTouchDrag: true,
            },
            handleScale: {
                axisPressedMouseMove: true,
                pinch: true,
            },
        });

        // Add candlestick series
        candlestickSeriesRef.current = chartRef.current.addCandlestickSeries({
            upColor: '#26a69a',
            downColor: '#ef5350',
            wickUpColor: '#26a69a',
            wickDownColor: '#ef5350',
        });

        // Add volume line series
        volumeSeriesRef.current = chartRef.current.addLineSeries({
            color: 'rgba(34, 166, 242, 0.2)', // Set opacity to 0.2
            lineWidth: 2,
            priceScaleId: 'left', // Use left y-axis
            priceLineVisible: false,
        });

        // Ensure the chart fits the data
        chartRef.current.timeScale().fitContent();

        // Mark chart as ready
        setChartReady(true);

        // Resize chart on window resize
        const handleResize = () => {
            if (chartRef.current && chartContainerRef.current) {
                chartRef.current.applyOptions({
                    width: chartContainerRef.current.clientWidth,
                    height: chartContainerRef.current.clientHeight,
                });
            }
        };

        window.addEventListener('resize', handleResize);

        // Cleanup on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
            if (chartRef.current) {
                chartRef.current.remove();
            }
        };
    }, []);

    return (
        <div className="chart-wrapper">
            <h5 className="chart-title">{symbol} Historical Price and Volume</h5>
            <div ref={chartContainerRef} className="chart-container" />
            {loading && <div className="loading-overlay">Loading...</div>}
        </div>
    );
}

export default TradingViewChart;